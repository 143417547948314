@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

